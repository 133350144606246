<!--
  PACKAGE_NAME : src/pages/euc/chineseWall/blackList
  FILE_NAME : index.vue
  AUTHOR : jhcho
  DATE : 2024-06-11
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <Tab title="블랙리스트">
        <BlackList />
      </Tab>
      <Tab title="차단이력">
        <BlackHistory />
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import Tab from "@/components/common/tab.vue";
import Tabs from "@/components/common/tabs.vue";
import BlackList from "@/pages/euc/chineseWall/blackList/black-list.vue";
import BlackHistory from "@/pages/euc/chineseWall/blackList/black-history.vue";

export default {
  components: {
    Tabs,
    Tab,
    BlackList,
    BlackHistory
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  methods: {
    async tabSelectedIndex(index) {
      this.tabIndex = index;
    },
  },
}
</script>
<style scoped>

</style>